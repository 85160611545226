import { Link } from 'react-router-dom';

import styles from './footer.module.scss';

import { FaLinkedin, FaPhoneSquareAlt, FaEnvelope } from 'react-icons/fa';

const Footer = () => {
    return(
        <footer className={styles.footer}>
            <div className={styles.footerTop}>
                <div className={styles.contact}>
                    <li className={styles.linkedIn}><FaLinkedin /><a href='https://www.linkedin.com/company/servida-soft-llc/' target='_blank'>Servida Soft LLC</a></li>
                    <li className={styles.phone}><FaPhoneSquareAlt /><a href='tel:725.222.0694'>725.222.0694</a></li>
                    <li className={styles.email}><FaEnvelope /><a href='mailto:support@servidasoft.com'>support@servidasoft.com</a></li>
                </div>
                <div className={styles.newsletter}>
                    <input type='text' placeholder='Your Email here' />
                    <button>Subscribe</button>
                </div>
            </div>
            <div className={styles.footerBottom}>
                <div className={styles.copyright}>© 2023 - Servida Soft LLC</div>
                <div className={styles.legalLinks}>
                    <Link to='/' className={styles.legalLink}>Privacy</Link>
                    <Link to='/' className={styles.legalLink}>Terms of Service</Link>
                </div>
            </div>
        </footer>
    )
}

export default Footer;