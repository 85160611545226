import { TypeAnimation } from 'react-type-animation';

import styles from './home.module.scss';

import Logo from '../../assets/images/logo.svg';

const Home = () => {
    return(
        <div className={styles.home}>
            <figure>
                <img src={Logo} />
                <figcaption>
                    <h1>Servida Soft</h1>
                    <span>
                        <h2>specializing in </h2>
                        <TypeAnimation sequence={['Development', 3000, 'Innovation', 3000, 'Automation', 3000, 'Optimization', 3000]} wrapper="h2" className={styles.animated} repeat={Infinity} />
                    </span>
                </figcaption>
            </figure>
        </div>
    )
}

export default Home;