import { useState, useRef } from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import styles from './contact.module.scss';

import dots from '../../assets/images/contact/dots.png';

import { BsTelephoneFill, BsFillPersonFill, BsFillBuildingFill } from 'react-icons/bs';
import { MdOutlineAlternateEmail } from 'react-icons/md';
import { BiMessageDetail } from 'react-icons/bi';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';  

const Contact = () => {

    const [contactName, setContactName] = useState('');
    const [contactOrg, setContactOrg] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [contactMessage, setContactMessage] = useState('');
    
    const [contactStatus, setContactStatus] = useState('');

    const contactNameRef = useRef(null);
    const contactOrgRef = useRef(null);
    const contactEmailRef = useRef(null);
    const contactPhoneRef = useRef(null);
    const contactMessageRef = useRef(null);

    const sendMessage = () => {

        if(contactName !== '' && contactOrg !== '' && validateEmail(contactEmail) && contactPhone !== '' && contactMessage !== '') {
            setContactStatus('loading');

            fetch('https://n0ld6o9tpi.execute-api.us-west-1.amazonaws.com/PROD/contact', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', },
                body: JSON.stringify({
                    name: contactName,
                    org: contactOrg,
                    email: contactEmail,
                    phone: contactPhone,
                    message: contactMessage,
                }),
            })
            .then((response) => {
                response.status === 200 ? setContactStatus('success') : setContactStatus('fail')
                console.log(response);
            })
            .catch((error) => {
                setContactStatus('fail');
                console.log(error);
            });
        }

        else {
            contactNameRef.current.className = contactName === '' ? `${styles.error} ${styles.inputContainer}` : styles.inputContainer;
            contactOrgRef.current.className = contactOrg === '' ? `${styles.error} ${styles.inputContainer}` : styles.inputContainer;
            contactEmailRef.current.className = !validateEmail(contactEmail) ? `${styles.error} ${styles.inputContainer}` : styles.inputContainer;
            contactPhoneRef.current.className = contactPhone === '' ? `${styles.error} ${styles.inputContainer}` : styles.inputContainer;
            contactMessageRef.current.className = contactMessage === '' ? `${styles.error} ${styles.inputContainer}` : styles.inputContainer;
        }
    }

    return(
        <div className={styles.contact}>
            <div className={styles.contactInfo}> 
                <div className={styles.dots}><img src={ dots } alt='background dots' /></div>
                <h1>Contact us</h1>
                <p>Need to get in touch with us? Either fill out the form or contact us through any of our info below.</p>
                <ul>
                    <li><BsTelephoneFill /><a href='tel:725.222.0694'>725.222.0694</a></li>
                    <li><MdOutlineAlternateEmail /><a href='mailto:support@servidasoft.com'>support@servidasoft.com</a></li>
                </ul>
            </div>
            {
                contactStatus === 'start' || contactStatus === '' ?
                <div className={styles.contactForm}>
                    <div className={styles.inputContainer} ref={contactNameRef}><BsFillPersonFill /><input type='text' placeholder='Name' value={contactName} onChange={(e) => setContactName(e.currentTarget.value)}/></div>
                    <div className={styles.inputContainer} ref={contactOrgRef}><BsFillBuildingFill /><input type='text' placeholder='Organization' value={contactOrg}  onChange={(e) => setContactOrg(e.currentTarget.value)} /></div>
                    <div className={styles.inputContainer} ref={contactEmailRef}><MdOutlineAlternateEmail /><input type='text' placeholder='Email' value={contactEmail}  onChange={(e) => setContactEmail(e.currentTarget.value)} /></div>
                    <div className={styles.inputContainer} ref={contactPhoneRef}><BsTelephoneFill /><input type='text' placeholder='Phone' value={contactPhone}  onChange={(e) => setContactPhone(e.currentTarget.value)} /></div>
                    <div className={styles.inputContainer} ref={contactMessageRef}><BiMessageDetail /><textarea placeholder='Message' value={contactMessage}  onChange={(e) => setContactMessage(e.currentTarget.value)} /></div>
                    <button onClick={sendMessage}>Send</button>
                </div>
                :
                contactStatus === 'loading' ? 
                <div className={styles.contactForm}>
                    <div className={styles.loading}>
                        <CircularProgress />
                        <p>Sending Message</p>
                    </div>
                </div>
                :
                contactStatus === 'success' ?
                <div className={styles.contactForm}>
                    <div className={`${styles.feedback} ${styles.success}`}>
                        <AiOutlineCheckCircle />
                        <p>Message Sent</p>
                    </div>
                </div>
                :
                <div className={styles.contactForm}>
                <div className={`${styles.feedback} ${styles.failed}`}>
                    <AiOutlineCloseCircle />
                    <p>Message Failed to Send</p>
                </div></div>
            }
        </div>
    )
}

export default Contact;


const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}