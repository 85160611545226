import React, { useEffect, useState, useRef } from 'react';

import styles from './subnav.module.scss';

const Subnav = (props) => {
    const icon = props.icon;
    const sections = props.sections;

    const subNavRef = useRef(null);
    const [activeSection, setActiveSection] = useState(null);

    useEffect(() => {
        window.addEventListener('scroll', checkActive);
    }, []);

    const scroll = (ref) => {

        if(subNavRef.current) {
            window.scrollTo({
                top: ref.current.getBoundingClientRect().top + document.documentElement.scrollTop - subNavRef.current.clientHeight,
                behavior: 'smooth'
            })
        }
    }

    const checkActive = () => {

        if(subNavRef.current) {
            const currentScroll = window.scrollY + subNavRef.current.clientHeight;

            for(let i = 0; i < sections.length; i++) {
                
                if(sections[i][1].current) {
                    const sectionTop = sections[i][1].current.offsetTop;
                    const sectionBottom = sections[i][1].current.offsetTop + sections[i][1].current.offsetHeight;

                    if(window.scrollY + window.innerHeight >= document.body.scrollHeight) {
                        setActiveSection(sections.length - 1);
                        break;
                    }

                    else {
                        
                        if(currentScroll >= sectionTop && currentScroll < sectionBottom) {
                            setActiveSection(i);
                            break;
                        }
                    }
                }
            }
        }
    } 

    return(
        <div className={styles.subnav} ref={subNavRef}>
            {sections.map((section, i) => <li key={i} className={i === activeSection ? styles.active : ''} onClick={() => scroll(section[1])}>{section[0]}</li>)}
        </div>
    )
}

export default Subnav;