import { useRef } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import Subnav from '../../../components/subnav/subnav';

import { BsPlus, BsCheckCircle } from 'react-icons/bs';
import { AiOutlineCheckCircle } from 'react-icons/ai';

import styles from './pipex.module.scss';

import OverviewBG from '../../../assets/images/pipex/overview-bg.jpg';

import img1 from '../../../assets/images/mockups/1.gif';
import img2 from '../../../assets/images/mockups/2.gif';
import img3 from '../../../assets/images/mockups/3.gif';
import img4 from '../../../assets/images/mockups/4.gif';
import img5 from '../../../assets/images/mockups/5.gif';

import { LuRuler } from 'react-icons/lu';
import { TbArrowCurveRight, TbFireHydrant } from 'react-icons/tb';
import { BiExpand, BiWater, BiSolidDownload } from 'react-icons/bi';

const Pipex = () => {

    const sections = [
        ['Overview', useRef(null)],
        ['Benefits', useRef(null)],
        ['Features', useRef(null)],
        ['FAQ', useRef(null)],
        ['Testimonials', useRef(null)],
        ['Pricing', useRef(null)],
        ['Downloads', useRef(null)],
    ];

    return(
        <div className={styles.pipex}>
            <Subnav sections={sections} />
            <section className={styles.overview} ref={sections[0][1]} style={{backgroundImage: `url(${OverviewBG})`}}>
                <h1 className={styles.pipexLogo}>Pipe<span>X</span></h1>
                <article>
                    <h2 className={styles.sectionTitle}>Speeds up pipe network design in Civil 3D</h2>
                    <p className={styles.sectionMainText}>
                        Creates accurate degrees for your bend connections. Quickly develop through design requirements, challenging pipe layouts, direction change, and obstacles.
                    </p>
                    <div className={styles.downloadContainer}>
                        
                        <a href='/downloads/ServidaSoftInstaller.msi' className={styles.download}>
                            <p>Download</p>
                            <BiSolidDownload />
                        </a>
                        <span>Try it now for free</span>
                    </div>
                </article>
            </section>
            <section className={styles.solutions} ref={sections[1][1]}>
                <div className={styles.intro}>
                    <h1>Benefits</h1>
                    <p>
                        PipeX offers a comprehensive suite of cutting-edge software tools designed to transform the way you approach design and engineering projects.
                    </p>
                </div>
                <div className={styles.solution}>
                    <li><BsCheckCircle />Say goodbye to manual tasks and enjoy faster project turnaround</li>
                    <li><BsCheckCircle />Reduce costly rework and ensure your projects meet the highest standards of quality and functionality</li>
                    <li><BsCheckCircle />Cater to various industries and disciplines, from civil engineering and architecture</li>
                </div>
            </section>
            <section className={styles.features} ref={sections[2][1]}>
                <h2 className={styles.sectionTitle}>Features</h2>
                <div className={styles.featureList}>
                    <figure className={styles.feature}>
                        <img src={img1} alt='Clearance from objects' />
                        <figcaption>
                            <h3><LuRuler />Clearance from objects</h3>
                            <p>You can keep your distance from other objects, making sure that your pipe network meets clearance requirements.</p>
                        </figcaption>
                    </figure>
                    <figure className={styles.feature}>
                        <img src={img2}  alt='Water bends' />
                        <figcaption>
                            <h3><BiWater />Water bends</h3>
                            <p>You can choose bend angles while drawing your waterline.</p>
                        </figcaption>
                    </figure>
                    <figure className={styles.feature}>
                        <img src={img3}  alt='Clearance between utilities' />
                        <figcaption>
                            <h3><TbFireHydrant />Clearance between utilities</h3>
                            <p>You can keep your distance from other utilities, making sure that your pipe network meets clearance requirements from other utilities.</p>
                        </figcaption>
                    </figure>
                    <figure className={styles.feature}>
                        <img src={img4}  alt='Curvy roads' />
                        <figcaption>
                            <h3><TbArrowCurveRight />Curvy roads</h3>
                            <p>Your pipe network stays inside the boundary, making it easy for you to draw your pipe network in curvy roads.</p>
                        </figcaption>
                    </figure>
                    <figure className={styles.feature}>
                        <img src={img5}  alt='Check clearance' />
                        <figcaption>
                            <h3><BiExpand />Check clearance</h3>
                            <p>You can verify that your pipe network meets clearance requirements from objects and other utilities. In addition, you can zoom in to the locations where clearance requirements are not met.</p>
                        </figcaption>
                    </figure>
                </div>
            </section>
            <section className={styles.faq} ref={sections[3][1]}>
                <h2 className={styles.sectionTitle}>Frequently Asked Questions</h2>
                <div className={styles.accordion}>
                    <Accordion disableGutters classes={{ root: styles.root }}>
                        <AccordionSummary classes={{ root: styles.summary, content: styles.summaryContent }} expandIcon={<BsPlus />}>Can you use PipeX with an existing Pipe Network?</AccordionSummary>
                        <AccordionDetails classes={{ root: styles.details, content: styles.detailsContent }}>Yes. All design criteria and features of PipeX will work on new and existing Pipe Networks.</AccordionDetails>
                    </Accordion>
                    <Accordion disableGutters classes={{ root: styles.root }}>
                        <AccordionSummary classes={{ root: styles.summary, content: styles.summaryContent }} expandIcon={<BsPlus />}>Can you set multiple horizontal clearance criteria in PipeX?</AccordionSummary>
                        <AccordionDetails classes={{ root: styles.details, content: styles.detailsContent }}>This has been requested by multiple people. If you are one of those people, please sign up for our newsletter to get updates when this feature become available.</AccordionDetails>
                    </Accordion>
                    <Accordion disableGutters classes={{ root: styles.root }}>
                        <AccordionSummary classes={{ root: styles.summary, content: styles.summaryContent }} expandIcon={<BsPlus />}>Can you draw your pipe from an offset to your alignment?</AccordionSummary>
                        <AccordionDetails classes={{ root: styles.details, content: styles.detailsContent }}>This has been requested by multiple people. If you are one of those people, please sign up for our newsletter to get updates when this feature become available.</AccordionDetails>
                    </Accordion>
                    <Accordion disableGutters classes={{ root: styles.root }}>
                        <AccordionSummary classes={{ root: styles.summary, content: styles.summaryContent }} expandIcon={<BsPlus />}>Can you edit pipe slopes with PipeX?</AccordionSummary>
                        <AccordionDetails classes={{ root: styles.details, content: styles.detailsContent }}>This has been requested by multiple people. If you are one of those people, please sign up for our newsletter to get updates when this feature become available.</AccordionDetails>
                    </Accordion>
                    <Accordion disableGutters classes={{ root: styles.root }}>
                        <AccordionSummary classes={{ root: styles.summary, content: styles.summaryContent }} expandIcon={<BsPlus />}>How to cancel a subscription?</AccordionSummary>
                        <AccordionDetails classes={{ root: styles.details, content: styles.detailsContent }}>You can cancel your subscription anytime by sending an email to admin@servidasoft.com using your account’s email address and a brief description of what you are trying to do. </AccordionDetails>
                    </Accordion>
                    <Accordion disableGutters classes={{ root: styles.root }}>
                        <AccordionSummary classes={{ root: styles.summary, content: styles.summaryContent }} expandIcon={<BsPlus />}>Can PipeX edit the pipe network vertically?</AccordionSummary>
                        <AccordionDetails classes={{ root: styles.details, content: styles.detailsContent }}>This has been requested by multiple people. If you are one of those people, please sign up for our newsletter to get updates when this feature become available.</AccordionDetails>
                    </Accordion>
                </div>
            </section>
            <section className={styles.testimonials} ref={sections[4][1]}>
                <article>
                    <p>Servida Soft is an amazing program that saves so much time when working with pipe networks. You no longer have to offset lines or figure out the angles for pipe bends because the program does it all for you</p>
                    <span>Danielle Smith, Civil Engineer, Atkins</span>
                </article>
                <article>
                    <p>Great software for anyone using AutoCad Civil 3D. ...it becomes an intrinsic part of your professional life using AutoCad Civil 3D</p>
                    <span>Adrian Gebilaguin, Civil Engineer, Actus</span>
                </article>
            </section>
            <section className={styles.pricing} ref={sections[5][1]}>
                <h2 className={styles.sectionTitle}>Pricing</h2>
                <div className={styles.price}>
                    <div className={styles.amount}>
                        <b>$10</b>
                        <span>per user per month</span>
                    </div>
                    <div className={styles.info}>
                        <b>clear and simple pricing</b>
                        <ul className={styles.pricingFeatures}>
                            <li><AiOutlineCheckCircle />1 month free trial</li>
                            <li><AiOutlineCheckCircle />Frequent Updates</li>
                            <li><AiOutlineCheckCircle />Email and Phone support</li>
                            <li><AiOutlineCheckCircle />Cancel anytime</li>
                            <li><AiOutlineCheckCircle />Volume Discounts</li>
                            <li><AiOutlineCheckCircle />Training Resources</li>
                            <li><AiOutlineCheckCircle />Customization Assistance</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className={styles.downloads} ref={sections[6][1]}>
                <h1>Get started with PipeX today</h1>
                <a href='/downloads/ServidaSoftInstaller.msi' className={styles.download}>
                    <p>Download</p>
                    <BiSolidDownload />
                </a>
            </section>
        </div>
    )
}

export default Pipex;