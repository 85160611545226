import { useState } from 'react';
import { Link } from 'react-router-dom';

import styles from './header.module.scss';

import { GrMenu, GrFormDown } from 'react-icons/gr';
import { BiLogIn } from 'react-icons/bi'; 

const Header = () => {

    const [showMainNav, setShowMainNav] = useState(false);
    const [showProductsNav, setShowProductsNav] = useState(false);
    
    const closeNav = () => {
        setShowMainNav(false);
        setShowProductsNav(false);
    }

    return(
        <header className={styles.header}>
            <Link to='/' className={styles.logo} onClick={() => closeNav()}><h1>Servida Soft</h1></Link>
            <div className={styles.showNav} onClick={() => setShowMainNav(state => !state)}><GrMenu /></div>
            <div className={`${styles.mainNav} ${showMainNav && styles.show}`}>
                <div className={styles.subNav}>
                    <ul className={styles.subNavParent} onClick={() => setShowProductsNav(state => !state)}>Solutions <GrFormDown /></ul>
                    <ul className={`${styles.subNavChildren} ${showProductsNav && styles.show}`}>
                        <Link to='/solutions/pipex' className={styles.subNavLink} onClick={() => closeNav()}>
                            <b className={styles.pipex}>Pipe<span>X</span></b>
                            <p className={styles.desc}>Speeds Up Pipe Network Design in Civil 3D</p>
                        </Link>
                    </ul>
                </div>
                <Link to='/contact' className={styles.navLink} onClick={() => closeNav()}>Contact</Link>
                <Link to='https://servidasoft.azurewebsites.net/Account/Login' className={`${styles.navLink} ${styles.login}`} onClick={() => closeNav()}><BiLogIn />Login</Link>
            </div>
        </header>
    )
}

export default Header;