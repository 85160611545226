import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, redirect } from 'react-router-dom';

import Header from './layouts/header/header';
import Footer from './layouts/footer/footer';

import Home from './pages/home/home';

import Pipex from './pages/solutions/pipex/pipex';

import Contact from './pages/contact/contact';

import './styles/main.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className='main'>
        <Header />
        <div className='content'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/solutions/pipex' element={<Pipex />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/productkey/activateproduct' Component={ () => { window.location = 'https://servidasoft.azurewebsites.net/productkey/activateproduct'; return; } }></Route>
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  </React.StrictMode>
);


//root.render(<BrowserRouter><Home /></BrowserRouter>);